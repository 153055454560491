import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'
import { HomePageWrapper } from '@retina-packages/retina-theme-bootstrap'

export const DTCHomeWrapper = styled(HomePageWrapper)`
  &.home-page {
    .homepage-wrapper {
      .o-container {
        @media ${device.mobilemax} {
          padding: 0 2rem;
        }
      }
      .c-hero-banner__content {
        a{
          &:after {
            @media ${device.ipadLandscapemin} {
              left: 10px;
            }
          }
        }
      }
    }
    .global-wrapper {
      .homepage-wrapper {
        .home-video-section {
          .video-section__inner-content {
            .video-transcript-copy {
              p {
                @media ${device.tabletMax} {
                  font-size: 16px;
                }
              }
            }
            .video-section__inner-content__video-btn {
              .dtp-arrow-down {
                &:before {
                  top: 3px;
                  @media ${device.ipadLandscapemin} {
                    left: 1px;
                  }
                }
                &.rotate {
                  top: 3px;
                }
              }
            }
          }
        }
      }
    }
    .footer {
      .o-backtotop-btn {
        @media ${device.ipadLandscapemin} {
          top: -135px;
        }
      }
    }
  }
  .homepage-wrapper {
    background: #fff;
    .home-page-footer-us {
      .footer-block {
        .footer-block--row {
          .footer-inner-block {
            h3.o-common-title {
                margin-bottom: 0.5rem;
            }
            p {
              margin-bottom: 0;
              @media ${device.ipadLandscapemin} {
                margin-bottom: 0.5rem;
              }
            }
            span {
              a {
                margin-top: 15px;
                @media ${device.ipadLandscapemin} {
                 font-size: 22px;
                }
                &:after {
                  @media ${device.tabletMax} {
                    margin: 8px auto auto;
                  }
                }
              }
            }
            span.text-para-wrap {
              @media ${device.ipadLandscapemin} {
                padding: 0 5px 15px;
              }
              a {
                margin-left: 0;
                margin-top: 15px;
                font-size: 1.8rem;
                line-height: 2.6rem;
                @media ${device.tabletMax} {
                  margin-top: 8px;
                }
                @media ${device.ipadLandscapemin} {
                  font-size: 2.2rem;
                  line-height: 2.4rem;
                }
                &:after {
                  display: block;
                  margin: 8px auto 0;
                  left: 0;
                  @media ${device.laptop} {
                    display: inline-block;
                    left: 7px;
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    .slider-main{
      @media ${device.tablet} and ${device.laptopair2max} {
        height: 1130px;
      }
    }
    .slick-slider {
      .slick-list {
        @media ${device.mobileMedium} {
          height: 1140px;
        }

        .slick-track {
          .slick-slide {
            .c-hero-banner-heading-con {
              @media screen and (max-width: 320px) {
                bottom: 67% !important;
              }
              @media ${device.mobileS} and ${device.mobileMedium} {
                bottom: 57.2%;
              }
              @media ${device.laptopPros} {
                padding: 0 2.5rem;
              }
              @media ${device.tablet} and ${device.laptopMedium} {
                padding: 0;
                bottom: 47%;
              }
              @media ${device.ipadLandscapemin} {
                bottom: 50.2%;
              }
              @media ${device.extraLargeDesktop} {
                bottom: 38.2%;
              }

              h2 {
                @media screen and (max-width: 320px) {
                  line-height: 3.6rem !important;
                }
                @media ${device.laptopMedium} {
                  font-size: 3.2rem;
                  line-height: 3.8rem;
                }
                margin-bottom: 1rem;
              }
            }
            .gatsby-image-wrapper {
              img {
                @media ${device.tablet} {
                  height: 1130px;
                }
                @media ${device.smalldeskstrt} {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .c-hero-banner__content {
      @media ${device.mobilemax} {
        bottom: 2% !important;
      }
      @media ${device.mobileMedium} {
        bottom: 0.9%;
      }
      @media ${device.tablet} and ${device.laptopMedium} {
        padding: 0 25px;
      }
      p {
        @media ${device.mobileXSMax} {
          margin: 1rem 0 !important;
          line-height: 2.6rem;
        }
        @media ${device.mobileS} and ${device.mobilemax} {
          margin: 1.5rem auto !important;
        }
        @media ${device.mobileMedium} {
          margin: 2.5rem auto;
          padding: 0 7px;
        }
        @media ${device.tablet} and ${device.laptopMedium} {
          padding: 0 7px;
        }
      }
      span {
        p {
          a {
            &:after {
              @media ${device.tabletMax} {
                left: 0 !important;
              }
            }
          }
        }
      }
    }
    .infographic {
      .o-container {
        small {
          color: #000;
        }
      }
      span.inupto {
        @media ${device.laptopMedium} {
          margin-top: -2px;
        }
      }
      .o-inner {
        .genetic-cause {
          padding: 74px 0px 44px;
          @media ${device.ipadLandscapemin} {
            padding: 114px 0px 88px;
          }
          .genetic-cause__right-text-secion {
            p {
              @media ${device.ipadLandscapemin} {
               margin-top: -27px;
              }
            }
          }
        }
      }
    }
    .video-section {
      &:nth-child(1) {
        padding-bottom: 20px;
      }
      .video-section__inner-content {
        @media ${device.laptopair2max} {
          padding-bottom: 20px;
        }

        &.last-video-section {
          padding-bottom: 28px;
          padding-top: 0px;
          margin-top: 40px;
          @media ${device.ipadLandscapemin} {
            padding-top: 88px;
            padding-bottom: 120px;
            margin-top: 0;
          }
        }
        padding-bottom: 0;
      }
    }
  }
`
