export const patientHome: any = {
    patHomeMainMenu: "Main Navigational Menu Consumer GCSO",
    patHomeFooterMenu: "Footer Navigation Menu Consumer GCSO",
    patHomeCarousel: "Homepage Carousal",
    patFooter: "Footer DTC HTML Canada",
    patExitPopup: "Retina Canada Exit Popup HTML",
    patHCPSwitcher: "HCP Switcher Modal Pop Up",
    patTopNav: "Top Navigation Menu Retina Canada HTML DTC",
    patVideo1: "Home Brightcove Top Video Section GCSO",
    patVideo2: "Home Brightcove Video Section GCSO",
    patInfographic: "Infographic Percentage Home Consumer GCSO ",
    patFootrCallout: "Homepage Footer Callout HTML DTC Canada",
    patSiteLogo: "Site logos DTC",
    carousalGTMs: {
      playBtnGTM: {
        'className': 'gtm-link-internal',
        'data-gtm-event-label': 'Hero image - play',
        'aria-label': 'Play slideshow'
      },
      pauseBtnGTM: {
        'className': 'gtm-link-internal',
        'data-gtm-event-label': 'Hero image - pause',
        'aria-label': 'Pause slideshow'
      },
      playBtnAriaDescription: ' ',
      pauseBtnAriaDescription: ' '
    },
    video1Attrs: {
      viewTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "The genetic testing story of Ramya - View transcript",
          "className": "gtm-accordion",
          "aria-label": "View transcript for The genetic testing story of Ramya"
        }
      ],
      hideTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "The genetic testing story of Ramya - Hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Hide transcript for The genetic testing story of Ramya"
        }
      ]
    },
    video2Attrs: {
      viewTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "Genes 101 - View transcript",
          "className": "gtm-accordion",
          "aria-label": "View transcript for Genes 101"
        },
        {
          "data-gtm-event-label": "Inheritance 101 - View transcript",
          "className": "gtm-accordion",
          "aria-label": "View transcript for Inheritance 101"
        }
      ],
      hideTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "Genes 101 - Hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Hide transcript for Genes 101"
        },
        {
          "data-gtm-event-label": "Inheritance 101 - Hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Hide transcript for Inheritance 101"
        }
      ]
    },
    redirectLangUrl: {
      "redirectUrl": {
        "en": "/",
        "fr": "/fr/"
      }
    },
    backToTopGTM: "Back to top - Homepage",
    footerClassName: "home-footer"
  }
